<template>
  <div id="contacts">
    <mainMenu></mainMenu>
    <div class="wrapper">
      <h1 class="title">Контакты</h1>
      <p>
        Физическое лицо-предприниматель
        <br />
        Погарский Антон Юрьевич
      </p>
      <p>
        Дата и номер записи в Едином государственном реестре юридических лиц,
        <br />
        физических лиц-предпринимателей и общественных формирований:
        <br />
        28.03.2018 года, № 21030000000096301;
      </p>
      <p>
        Адрес (место фактического проживания): 69600, Украина,
        <br />
        Запорожская область, город Запорожье, Коммунарский район,
        <br />
        ул. Реконструктивная, дом 3,
      </p>
      <p>Регистрационный номер учетной карточки налогоплательщика: 2987814459;</p>
      <p>Адрес электронной почты: dentabula.AP@gmail.com;</p>
      <p>
        Режим работы:
        <br />
        С понедельника по пятницу каждую неделю, с 10 час. 00 мин. до 17 час. 00 мин .;
      </p>
      <p>Тел .: + 38 097 977 39 98.</p>
    </div>
    <mainFooter></mainFooter>
  </div>
</template>

<script>
import mainMenu from "./../mainMenu/mainMenu.vue";
import mainFooter from "./../mainFooter/mainFooter.vue";
// import translate from './translate.js'

export default {
  name: "Contacts",
  components: { mainMenu, mainFooter },
  data() {
    return {
      // translate: translate,
    };
  },
  computed: {
    currentLang() {
      return this.$store.getters.currentLang;
    },
  },
};
</script>

<style lang="sass">
#contacts
	.wrapper
		display: flex
		max-width: 1024px
		margin: 0 auto
		padding: 0 0.5rem
		min-height: calc(100vh - 280px)
		.title
			font-family: 'Ropa Sans Pro SC Regular'
		p
			margin-bottom: 1rem
</style>
